@use '@/shared/ui/button/adaptive' as *;
@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.container {
  display: flex;
  flex-direction: column;
}

.title p:nth-child(2) {
  @include typography-base;
  @include weight-medium;
  color: var(--alias-modal-on-primary-text-secondary);

  @include min-428-break {
    @include typography-m;
  }

  @include min-1024-break {
    @include typography-s;
  }

  @include min-1440-break {
    @include typography-m;
  }
}

.inputs-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  @include min-428-break {
    gap: 12px;
  }
  @include min-1024-break {
    gap: 8px;
  }
  @include min-1440-break {
    gap: 12px;
  }
}

.button {
  @include buttonSizes('38', '46', '46', '42', '50', '50');
}
