@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;
@use '@button' as *;

.modal {
  width: 360px;
  outline: 4px solid var(--cst-authorization-outline);
  outline-offset: -4px;
  background-color: var(--cst-authorization-background);
  border-radius: var(--cornerradius14);

  @include min-1440-break {
    width: 436px;
    border-radius: var(--cornerradius18);
  }
}

.header {
  height: 36px;
  @include min-1440-break {
    height: 46px;
  }
}

.item-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('../../../../public/assets/drop-page/item-received-stars-bg.webp')
    center center no-repeat;
  background-size: cover;
  @include fixedSize(100%, 130px);
  @include min-1440-break {
    @include fixedSize(100%, 156px);
  }

  &-item {
    position: relative;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      @include singleFixedSize(86px);
      @include min-428-break {
        @include singleFixedSize(110px);
      }
    }
    span {
      padding: 0;
    }
    svg {
      @include fixedSize(67px, 80px);
      @include min-428-break {
        @include fixedSize(87px, 104px);
      }
    }
  }
}

.button {
  @include buttonSizes('42', '42', '42', '42', '50', '50');
}

.info {
  p {
    @include typography-s;
    @include min-1440-break {
      @include typography-base;
    }
    font-weight: 500;
  }
}
