@use '@button' as *;
@use '@typography' as *;
@use '@fixed-size' as *;
@use '@queries' as *;

.drop-desktop-egg-wrapper {
  display: grid;
  grid-template-columns: minmax(100%, 506px);

  @include min-1440-break {
    grid-template-columns: minmax(100%, 730px);
  }

  .item {
    position: relative;
    background: var(--global-white);
    overflow: hidden;
    display: flex;
    aspect-ratio: 1 /1;
    border-radius: var(--cornerradius12);
    outline: 2px solid var(--cst-cards-item-block-outline);
    outline-offset: -2px;
    @include fixedSize(inherit, inherit);

    @include min-1440-break {
      border-radius: var(--cornerradius16);
    }

    .item-background {
      position: absolute;
      @include fixedSize(100%, 100%);
      aspect-ratio: 1/1;
      color: var(--global-blue600);
    }

    .item-shadow {
      position: absolute;
      bottom: 13%;
      left: 0;
      right: 0;
      margin-inline: auto;
      display: flex;
      background: #d9d9d9;
      scale: 0.379;
      aspect-ratio: 110 / 30;
      border-radius: 50%;

      @include min-744-break {
        scale: 0.32;
        bottom: 17%;
      }
    }

    .item-egg {
      position: absolute;
      pointer-events: none;
      bottom: 0px;
      left: 0;
      top: -8%;
      right: 0;
      margin-inline: auto;
      aspect-ratio: 134 / 140;

      opacity: 0;
      animation: none;
      visibility: hidden;
      scale: 0;

      z-index: 2;
      -webkit-transition:
        visibility 1.5s ease-in-out,
        opacity 1.5s ease-in-out;
      -moz-transition:
        visibility 1.5s ease-in-out,
        opacity 1.5s ease-in-out;
      -o-transition:
        visibility 1.5s ease-in-out,
        opacity 1.5s ease-in-out;

      &.show {
        visibility: visible;
        opacity: 1;
        animation: scale 2s ease-in-out;
      }

      &.hide {
        display: none;
      }

      @keyframes scale {
        0% {
          scale: 0;
        }
        100% {
          scale: 0.478;
        }
      }

      @include fixedSize(100%, 100%);

      @include min-744-break {
        scale: 0.53;
      }
    }
  }
}
