@use '@fixed-size' as *;
@use '@typography' as *;
@use '@button' as *;
@use '@queries' as *;

.drop-options-wrapper {
  display: flex;
  gap: 12px;
  align-items: flex-start;
  position: relative;
  max-height: 100%;
  height: 100%;
  overflow: hidden;

  @include min-1440-break {
    gap: 16px;
  }

  .close-btn {
    position: relative;
    top: 13px;
    @include singleFixedSize(42px);

    @include min-1440-break {
      @include singleFixedSize(54px);
      top: 16px;
    }
  }

  .drop-options {
    display: flex;
    flex-direction: column;
    background: var(--global-white);
    outline-offset: -2px;
    outline: 2px solid var(--cst-offer-outline);
    border-radius: var(--cornerradius16);
    gap: 18px;
    padding: 16px;
    @include fixedSize(354px, 506px);
    width: max-content;

    @include min-1440-break {
      padding: 20px;
      gap: 24px;
      @include fixedSize(464px, 730px);
    }

    &-header {
      display: flex;
      justify-content: space-between;

      .header-label {
        span[id='label-icon'] {
          color: #7832ea;
          @include singleFixedSize(22px);

          @include min-1440-break {
            @include singleFixedSize(26px);
          }
        }

        span[id='label-text'] {
          @include typography-m;
          @include weight-semi-bold;

          @include min-1440-break {
            @include typography-l;
          }
        }
      }

      .header-restart-btn {
        @include singleFixedSize(36px);

        @include min-1440-break {
          @include singleFixedSize(46px);
        }

        &--icon {
          color: #8767d1;
          @include singleFixedSize(18px);

          @include min-1440-break {
            @include singleFixedSize(20px);
          }
        }
      }
    }

    &-list-wrapper {
      display: flex;
      flex-direction: column;
      max-height: 414px;
      overflow-x: hidden;
      overflow-y: auto;
      flex: 1;

      @include min-1440-break {
        max-height: 612px;
      }

      .drop-options-list {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-right: 18px;
      }
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      justify-self: flex-end;

      .smash-btn {
        @include buttonSizes('42', '46', '52', '42', '54', '54');

        span[id='button-span-text'] {
          @include typography-base;
          @include weight-semi-bold;
        }

        &--icon {
          @include singleFixedSize(18px);

          @include min-1440-break {
            @include singleFixedSize(22px);
          }
        }
      }
    }
  }
}
