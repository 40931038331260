@use '@content' as *;
@use '@queries' as *;

.drop-mobile {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  .wrapper {
    @include mainContainerStyles;
    @include containerPaddings;
    max-width: 1023px;
    padding-top: 0 !important;
  }

  &__content {
    @include mainContainerStyles;
  }
}
